import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ErrorIcon from '@mui/icons-material/Error';
import './FileUpload.css';
import useResumeUpload from '../../../hooks/useResumeUpload';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useUserSettings } from '../../../UserSettingsContext';

const FileUpload = ({ onUpload }) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const { resumeLabel, labelVectorize, fetchJobByVectorized, fectchJobByExistFile } = useResumeUpload();
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const { userSettings, setUserSettings } = useUserSettings();
  const [existResume, setExistResume] = useState(false);

  useEffect(() => {
    setExistResume(!!userSettings?.filename);
  }, [userSettings?.filename]);
  useEffect(() => {
    const previouslyAgreed = sessionStorage.getItem('agreedToTerms') === 'true';
    setAgreedToTerms(previouslyAgreed);
  }, []);

  const onDrop = (acceptedFiles) => {
    if (!isAuthenticated) {
      setError('Please login to upload your resume');
      return;
    }
    if (acceptedFiles && acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      setError(null);
    }
  };
  const handleLoginRedirect = () => {
    navigate('/login', { state: { from: window.location.pathname } });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
    },
    multiple: false,
  });

  const renderUploadContent = () => {
    if (authLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress size={24} />
        </Box>
      );
    }

    if (!isAuthenticated) {
      return (
        <Box
          sx={{
            textAlign: 'center',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InsertDriveFileIcon className="file-icon" />
          <p>Login to upload resume</p>
        </Box>
      );
    }

    return (
      <>
        <input {...getInputProps()} />
        <InsertDriveFileIcon className="file-icon" />
        <p>
          {isDragActive
            ? 'Drop your resume here'
            : selectedFile
            ? `Selected: ${selectedFile.name}`
            : existResume
            ? `Saved: ${userSettings?.filename}`
            : 'Drag resume here, or click to select'}
        </p>
      </>
    );
  };

  const handleUpload = async () => {
    if (selectedFile && agreedToTerms) {
      setIsLoading(true);
      setError(null);
      setProgress(0);
      try {
        // Step 1: Label Resume
        const labelFormData = new FormData();
        labelFormData.append('file', selectedFile);
        const labeledResume = await resumeLabel(labelFormData);
        setProgress(33);

        // Step 2: Vectorize Resume
        const vectorizedResume = await labelVectorize(labeledResume);
        setProgress(66);

        // Step 3: Upload and Match Jobs
        const result = await fetchJobByVectorized(vectorizedResume);
        setProgress(100);
        onUpload(result);
        setSelectedFile(null);
      } catch (err) {
        console.error('Error in handleUpload:', err);
        setError(err.message || 'An error occurred while processing your resume');
      } finally {
        setIsLoading(false);
        setSelectedFile(null);
        setProgress(0);
      }
    }
  };

  const handleExistFile = async () => {
    try {
      setIsLoading(true);
      const result = await fectchJobByExistFile(userSettings.user_id);
      onUpload(result);
      setIsLoading(false);
      setExistResume(false);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleAgreementChange = (event) => {
    const agreed = event.target.checked;
    setAgreedToTerms(agreed);
    sessionStorage.setItem('agreedToTerms', agreed);
  };

  return (
    <Box className="file-upload-section">
      <Typography variant="subtitle1" className="upload-header">
        <CloudUploadIcon className="upload-header-icon" />
        Boost your job search with resume matching
      </Typography>
      <Box
        className="file-upload-container"
        onClick={() => !isAuthenticated && handleLoginRedirect()}
        sx={{
          opacity: !isAuthenticated ? 0.6 : 1,
          cursor: !isAuthenticated ? 'pointer' : 'auto',
        }}
      >
        <Box sx={{ pointerEvents: !isAuthenticated ? 'none' : 'auto' }}>
          <Tooltip title="Accepted formats: PDF, DOC, DOCX, TXT" arrow>
            <div {...getRootProps()} className="input-container">
              <input {...getInputProps()} />
              <InsertDriveFileIcon className="file-icon" />
              {renderUploadContent()}
            </div>
          </Tooltip>
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: '8px' }}>
              <ErrorIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px' }} />
              {error}
            </Typography>
          )}
          <div className="action-container">
            <FormControlLabel
              control={
                <Checkbox checked={agreedToTerms} onChange={handleAgreementChange} color="primary" size="small" />
              }
              label={<span className="agreement-container">I agree to the terms of service & privacy policy</span>}
            />
            <Button
              variant="contained"
              onClick={selectedFile ? handleUpload : existResume ? handleExistFile : undefined}
              disabled={(!selectedFile && !userSettings?.filename) || !agreedToTerms || isLoading}
              className="process-resume-button"
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Find Matching Jobs'}
            </Button>
          </div>
          {isLoading && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="text.secondary" align="center">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FileUpload;
