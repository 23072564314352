import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserSettingsProvider } from './UserSettingsContext';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Auth0Provider
        cocookieDomain={window.location.hostname}
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          prompt: 'login',
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email offline_access',
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
      >
        <UserSettingsProvider>
          <App />
        </UserSettingsProvider>
      </Auth0Provider>
    </Router>
  </React.StrictMode>
);
